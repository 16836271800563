import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/libre-baskerville";
import "@fontsource/montserrat";

import { Footer, Navigation, Title } from "./index";

const Body = (): JSX.Element => {
  return (
    <div className="relative">
      <StaticImage
        alt="Eye dropper next to plant."
        loading="eager"
        placeholder="blurred"
        src="../../../../images/cbd-truth-mission.jpg"
        style={{ minHeight: "830px" }}
      />
      <div className="absolute text-cbd-truth-dark-blue font-light max-w-lg left-0 top-0 ml-16 mt-16 z-10">
        <p className="font-bold text-xl" style={{ fontFamily: "Libre Baskerville" }}>Our mission</p>
        <p className="mt-5">CBD Truth was founded in 2017. Our mission is to <span className="font-semibold">provide information</span> for both consumers and health professionals about the <span className="font-semibold">benefits and risks of CBD.</span></p>
      </div>
    </div>
  );
};

const CBDTruthPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Montserrat" }}>
      <Title />
      <Navigation />
      <Body />
      <Footer />
    </div>
  );
};

export default CBDTruthPage;
